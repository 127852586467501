import style from './ProjectsHome.module.css';

const ProjectsHome = () => {
    return (
        <section className={style.projects}>
            <div className="container">
                <div className={style.projects__panel}>
                    <div className={`${style.projects__title} title`}>Разработанные проекты</div>
                    <div className={style.projects__description}>Разработанные проекты являются ключевым элементом любой организации, занимающейся инновациями, строительством, информационными технологиями или другими областями, требующими тщательного планирования и реализации.</div>
                </div>

                <div className={style.projects__content}>
                    <div className="row">
                        <div className={style.projects__col}>
                            <div className={style.projects__item}>
                                <a href="javascript:;" className={style.projects__link}>
                                    <div className={style.projects__img}>
                                        <img src="/images/portfolio/p-1.jpg" alt="" />
                                    </div>
                                    <div className={style.projects__name}>Интернет магазин PP Rolled</div>
                                    <div className={style.projects__text}>Компания по производству металлоконструкций.  Крупный интернет магазин для работы как с розничными покупалями, так и с оптовыми компаниями.</div>
                                </a>
                            </div>
                        </div>

                        <div className={style.projects__col}>
                            <div className={style.projects__item}>
                                <a href="javascript:;" className={style.projects__link}>
                                    <div className={style.projects__img}>
                                    <img src="/images/portfolio/p-2.jpg" alt="" />
                                    </div>
                                    <div className={style.projects__name}>Маркетплейс SF Market</div>
                                    <div className={style.projects__text}>Маркетплейс строительный материалов по всей России. Площадка которая предоставляет размещение и продажи своих товаров на данной платформе.</div>
                                </a>
                            </div>
                        </div>

                        <div className={style.projects__col}>
                            <div className={style.projects__item}>
                                <a href="javascript:;" className={style.projects__link}>
                                    <div className={style.projects__img}>
                                    <img src="/images/portfolio/p-3.jpg" alt="" />
                                    </div>
                                    <div className={style.projects__name}>Корпоративный сайт MЁD<sup> 2</sup></div>
                                    <div className={style.projects__text}>Дизайн-проекты для жилых и коммерческих пространств. Лучшие решения для вашего дома или квратиры, маленькой кухни или большого коттеджа</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectsHome;