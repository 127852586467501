import { Link, useLocation } from "react-router-dom";

const Breadcrumb = (props) => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);
  
    return (
        <div className="breadcrumbs">
            <ul>
                <li><Link to="/">Главная</Link></li>
                
                {
                    pathnames.map((value, index) => {
                        if (value == 'about') {
                            value = 'Обо мне';
                        } else if (value == 'portfolio') {
                            value = 'Портфолио';
                        } else if (value == 'blog') {
                            value = 'Блог';
                        } else if (value == 'contacts') {
                            value = 'Контакты';
                        }

                        const last = index === pathnames.length - 1;
                        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                        return (
                            <li key={index}>
                                {last ? (
                                    <span key={to}>&nbsp;<span className="sep">&gt;</span> {value}</span>
                                ) : (
                                    <span key={to}>
                                        &nbsp;<span className="sep">&gt;</span> <Link to={to}>{value}</Link>
                                    </span>
                                )}
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};
  
export default Breadcrumb;