import style from './Header.module.css';

import Nav from '../Block/Nav/Nav.js';
import Logo from '../Block/Logo/Logo.js';
import SocialList from '../Block/SocialList/SocialList.js';
import ButtonCallback from '../Block/Button/ButtonCallback.js';

const Header = () => {
    return (
        <header>
            <div className={style.header}>
                <div className="container">
                    <div className={style.header__row}>
                        <div className={style.header__logo}>
                            <a href="javascript:;" className={style.header__bar}>
                                <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                                    <path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6H20M4 12H20M4 18H20"/>
                                </svg>
                            </a>
                            <Logo />
                        </div>

                        <div className={style.header__menu}>
                            <Nav />
                        </div>

                        <div className={style.header__callback}>
                            <div className={style.header__social}>
                                <SocialList />
                            </div>

                            <ButtonCallback />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;