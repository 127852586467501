import style from './ServicesHome.module.css';

const ServicesHome = () => {
    return (
        <section className={`${style.services} services`}>
            <div className="container">
                <div className={style.services_panel}>
                    <div className={`${style.services_title} title`}>Услуги</div>
                    <div className={style.services__description}>Услуги веб-разработки играют ключевую роль в современном цифровом мире, обеспечивая компаниям и организациям возможность эффективно представлять себя в интернете.</div>
                </div>

                <div className={style.services__wrapper}>
                    <div className={style.services__list}>
                        <div className="row">
                            <div className={style.services__col}>
                                <div className={style.services__item}>
                                    <div className={style.services__img}>
                                        <img src="/images/services/services-1.jpg" alt="" />
                                    </div>
                                    <div className={style.services__content}>
                                        <div className={style.services__name}>Разработка веб-сервисов</div>
                                        <div className={style.services__text}>
                                            <ul className={style.services__list}>
                                                <li className={style.services__element}>Личные кабинеты</li>
                                                <li className={style.services__element}>B2B-порталы</li>
                                                <li className={style.services__element}>CRM</li>
                                                <li className={style.services__element}>Маркетплейсы</li>
                                                <li className={style.services__element}>Web-приложения</li>
                                                <li className={style.services__element}>Технические задания</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={style.services__col}>
                                <div className={style.services__item}>
                                    <div className={style.services__img}>
                                        <img src="/images/services/services-2.jpg" alt="" />
                                    </div>
                                    <div className={style.services__content}>
                                        <div className={style.services__name}>Поддержка и развитие IT</div>
                                        <div className={style.services__text}>
                                            <ul className={style.services__list}>
                                                <li className={style.services__element}>Ускорение сайтов</li>
                                                <li className={style.services__element}>Интеграции API</li>
                                                <li className={style.services__element}>Техподдержка сайта Битрикс</li>
                                                <li className={style.services__element}>Перенос сайта на 1C-Битрикс</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={style.services__col}>
                                <div className={style.services__item}>
                                    <div className={style.services__img}>
                                        <img src="/images/services/services-3.jpg" alt="" />
                                    </div>
                                    <div className={style.services__content}>
                                        <div className={style.services__name}>Создание сайтов</div>
                                        <div className={style.services__text}>
                                            <ul className={style.services__list}>
                                                <li className={style.services__element}>Интернет-магазины</li>
                                                <li className={style.services__element}>Landing Page</li>
                                                <li className={style.services__element}>Корпоративные сайты</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={style.services__col}>
                                <div className={style.services__item}>
                                    <div className={style.services__img}>
                                        <img src="/images/services/services-4.jpg" alt="" />
                                    </div>
                                    <div className={style.services__content}>
                                        <div className={style.services__name}>Подключение сторонних сервисов</div>
                                        <div className={style.services__text}>
                                            <ul className={style.services__list}>
                                                <li className={style.services__element}>Платежные системы</li>
                                                <li className={style.services__element}>Службы доставки</li>
                                                <li className={style.services__element}>Сервисы по рассылке</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHome;