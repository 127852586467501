import BannerHome from './Banner/BannerHome.js';
import ProjectsHome from './Projects/ProjectsHome.js';
import ServicesHome from './Services/ServicesHome.js';
import TechHome from './Tech/TechHome.js';
import BlogHome from './Blog/BlogHome.js';

const Home = (props) => {
    return (
        <div className="content">
            <BannerHome />

            <ProjectsHome />

            <ServicesHome />

            <TechHome />

            <BlogHome />
        </div>
    );
}

export default Home;