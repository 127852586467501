import PanelTitle from '../../Block/PanelTitle/PanelTitle.js';

const Contacts = (props) => {
    let title = 'Контакты';

    return (
        <div className="content">
            <PanelTitle title={title} />
        </div>
    );
}

export default Contacts;