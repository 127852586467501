import style from './BlogHome.module.css';

const BlogHome = () => {
    return (
        <section className={style.blog}>
            <div className="container">
                <div className={style.blog_panel}>
                    <div className={`${style.blog_title} title`}>Блог</div>
                    <div className={style.blog__description}>Добро пожаловать в наш блог о веб-разработке! Здесь вы найдете полезную информацию, советы и руководства, которые помогут вам освоить и углубить знания в этой увлекательной области.</div>
                </div>

                <div className="blog-content">
                    <div className="row">
                        <div className={style.blog__col}>
                            <div className={style.blog__item}>
                                <div className={style.blog__img}>
                                    <a href="javascript:;">
                                        <img src="/images/blog/blog-1.jpg" alt="" />
                                    </a>
                                </div>
                                <div className={style.blog__subtitle}>Разработка с нуля</div>
                                <div className={style.blog__date}>21 мая 2023</div>
                                <div className={style.blog__name}><a href="javascript:;">На Alto участвует в развитии социального проекта</a></div>
                                <div className={style.blog__text}>Разработка сайта «На Заводе» — некоммерческая инициатива команды Alto. Комментарий Ивана Ярославцева об итогах проекта. </div>
                            </div>
                        </div>

                        <div className={style.blog__col}>
                            <div className={style.blog__item}>
                            <div className={style.blog__img}>
                                    <a href="javascript:;">
                                        <img src="/images/blog/blog-2.jpg" alt="" />
                                    </a>
                                </div>
                                <div className={style.blog__subtitle}>Разработка с нуля</div>
                                <div className={style.blog__date}>21 мая 2023</div>
                                <div className={style.blog__name}><a href="javascript:;">На Alto участвует в развитии социального проекта</a></div>
                                <div className={style.blog__text}>Разработка сайта «На Заводе» — некоммерческая инициатива команды Alto. Комментарий Ивана Ярославцева об итогах проекта. </div>
                            </div>
                        </div>

                        <div className={style.blog__col}>
                            <div className={style.blog__item}>
                                <div className={style.blog__img}>
                                    <a href="javascript:;">
                                        <img src="/images/blog/blog-3.jpg" alt="" />
                                    </a>
                                </div>
                                <div className={style.blog__subtitle}>Разработка с нуля</div>
                                <div className={style.blog__date}>21 мая 2023</div>
                                <div className={style.blog__name}><a href="javascript:;">На Alto участвует в развитии социального проекта</a></div>
                                <div className={style.blog__text}>Разработка сайта «На Заводе» — некоммерческая инициатива команды Alto. Комментарий Ивана Ярославцева об итогах проекта. </div>
                            </div>
                        </div>

                        <div className={style.blog__col}>
                            <div className={style.blog__item}>
                                <div className={style.blog__img}>
                                    <a href="javascript:;">
                                        <img src="/images/blog/blog-4.jpg" alt="" />
                                    </a>
                                </div>
                                <div className={style.blog__subtitle}>Разработка с нуля</div>
                                <div className={style.blog__date}>21 мая 2023</div>
                                <div className={style.blog__name}><a href="javascript:;">На Alto участвует в развитии социального проекта</a></div>
                                <div className={style.blog__text}>Разработка сайта «На Заводе» — некоммерческая инициатива команды Alto. Комментарий Ивана Ярославцева об итогах проекта. </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogHome;