import { NavLink } from 'react-router-dom';

const Logo = (props) => {
    return (
        <div>
            <NavLink to="/">ShtondaDev <span>/></span></NavLink>
        </div>
    );
}

export default Logo;