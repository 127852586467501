import { NavLink } from 'react-router-dom';

const Nav = (props) => {
    return (
        <div>
            <ul>
                <li><NavLink to="/">Главная</NavLink></li>
                <li><NavLink to="/about/">Обо мне</NavLink></li>
                <li><NavLink to="/portfolio/">Портфолио</NavLink></li>
                <li><NavLink to="/blog/">Блог</NavLink></li>
                <li><NavLink to="/contacts/">Контакты</NavLink></li>
            </ul>
        </div>
    );
}

export default Nav;