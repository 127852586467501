import style from './TechHome.module.css';

const TechHome = () => {
    return (
        <section className={`${style.tech} tech`}>
            <div className="container">
                <div className={style.tech_panel}>
                    <div className={`${style.tech_title} title`}>Технологии</div>
                    <div className={style.tech__description}>Веб-разработка представляет собой комплекс процессов, включающих создание и поддержку веб-сайтов и веб-приложений. В этой области используются различные технологии</div>
                </div>

                <div className="tech-list">
                    <div className="row">
                        <div className={style.tech__col}>
                            <div className={style.tech__item}>
                                <div className={style.tech__name}>Backend</div>
                                <div className={style.tech__text}>Разрабатываем бэкенд, который обеспечивает быструю работу даже при высоких нагрузках. Разрабатываем API. Интегрируем с внешними системами.</div>
                                <div className={style.tech__list}>
                                    <ul>
                                        <li className={style.tech__element}>1С-Битрикс</li>
                                        <li className={style.tech__element}>PHP8</li>
                                        <li className={style.tech__element}>Sphinx</li>
                                        <li className={style.tech__element}>Docker</li>
                                        <li className={style.tech__element}>Mysql</li>
                                        <li className={style.tech__element}>Nginx</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={style.tech__col}>
                            <div className={style.tech__item}>
                                <div className={style.tech__name}>Frontend</div>
                                <div className={style.tech__text}>Создаем адаптивные сайты и SPA-приложения. Продумываем юзабилити. Используем современные технологии и инструменты.</div>
                                <div className={style.tech__list}>
                                    <ul>
                                        <li className={style.tech__element}>HTML5</li>
                                        <li className={style.tech__element}>CSS3</li>
                                        <li className={style.tech__element}>React/NextJS</li>
                                        <li className={style.tech__element}>Sass</li>
                                        <li className={style.tech__element}>Webpack</li>
                                        <li className={style.tech__element}>Git/Github</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={style.tech__col}>
                            <div className={style.tech__item}>
                                <div className={style.tech__name}>Mobile</div>
                                <div className={style.tech__text}>Разрабатываем кроссплатформенные мобильные приложения, под iOS и Android</div>
                                <div className={style.tech__list}>
                                    <ul>
                                        <li className={style.tech__element}>React Native</li>
                                        <li className={style.tech__element}>Golang</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={style.tech__col}>
                            <div className={style.tech__item}>
                                <div className={style.tech__name}>QA</div>
                                <div className={style.tech__text}>Проводим тестирование на каждом этапе проекта. Гарантируем качество продукта. </div>
                                <div className={style.tech__list}>
                                    <ul>
                                        <li className={style.tech__element}>Ручное тестирование</li>
                                        <li className={style.tech__element}>Автотесты</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TechHome;