import PanelTitle from '../../Block/PanelTitle/PanelTitle.js';

const About = (props) => {
    let title = 'Обо мне';

    return (
        <div className="content">
            <PanelTitle title={title} />

            <div className="content__wrapper">
                <div className="editor">
                    <div className="container">
                        <p>Я являюсь опытным разработчиком на платформе 1С-Битрикс, специализирующимся на создании 
                            высококачественных веб-решений для бизнеса. Мой опыт включает в себя разработку, настройку и 
                            оптимизацию корпоративных сайтов, интернет-магазинов и CRM-систем на базе Битрикс.</p>

                        <p>Благодаря глубоким знаниям архитектуры и функциональных возможностей 1С-Битрикс, я успешно реализую 
                            проекты любой сложности, начиная от внедрения готовых решений до разработки уникальных модулей и 
                            интеграций. Я обладаю навыками работы с API Битрикс, написания компонентов и создания 
                            высокопроизводительных сайтов, которые легко масштабируются и отвечают требованиям безопасности.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;