import './App.css';

import Header from './components/Header/Header.js';
import Footer from './components/Footer/Footer.js';

import Home from './components/Page/Home/Home.js';
import About from './components/Page/About/About.js';
import Portfolio from './components/Page/Portfolio/Portfolio.js';
import Blog from './components/Page/Blog/Blog.js';
import Contacts from './components/Page/Contacts/Contacts.js';

import {BrowserRouter, Routes, Route} from "react-router-dom";

const App = (props) => {
    return (
        <BrowserRouter>
            <div className="wrapper">
                <Header />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about/" element={<About />} />
                    <Route path="/portfolio/" element={<Portfolio />} />
                    <Route path="/blog/" element={<Blog />} />
                    <Route path="/contacts/" element={<Contacts />} />
                </Routes>
                
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;