import PanelTitle from '../../Block/PanelTitle/PanelTitle.js';

const Portfolio = (props) => {
    let title = 'Портфолио';

    return (
        <div className="content">
            <PanelTitle title={title} />
        </div>
    );
}

export default Portfolio;