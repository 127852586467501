import style from './Footer.module.css';

import Nav from '../Block/Nav/Nav.js';
import Logo from '../Block/Logo/Logo.js';
import SocialList from '../Block/SocialList/SocialList.js';
import ButtonCallback from '../Block/Button/ButtonCallback.js';

const Footer = () => {
    return (
        <footer className={style.footer}>
            <div className="container">
                <div className="footer-content">
                    <div className={style.footer__row}>
                        <div className={style.footer__logo}>
                            <Logo />
                        </div>

                        <div className={style.footer__menu}>
                            <Nav />
                        </div>

                        <div className={style.footer__callback}>
                            <div className={style.footer__social}>
                                <SocialList />
                            </div>

                            <ButtonCallback />
                        </div>

                    </div>
                </div>
                <div className={style.footer__copy}>© 2024 Штонда Александр</div>
            </div>
        </footer>
    );
}

export default Footer;