import Breadcrumb from "../Nav/BreadCrumb.js";
const PanelTitle = (props) => {
    return (
        <div className="panel">
            <div className="container">
                <Breadcrumb />
                <h1 className="panel__title">{props.title}</h1>
            </div>
        </div>
    );
}

export default PanelTitle;