import style from './BannerHome.module.css';

import SocialList from '../../../Block/SocialList/SocialList.js';
import ButtonCallback from '../../../Block/Button/ButtonCallback.js';

const BannerHome = () => {
    return (
        <section className={`${style.banner} banner`}>
            <div className="container">
                <div className="row">
                    <div className={`${style.banner__col_content} col-60`}>
                        <div className={style.banner__content}>
                            <div className={style.banner__title}>Веб-программист <br/>1С-Битрикс</div>
                            <div className={style.banner__text}>Занимаюсь разработкой сайтов на платформе 1С-Битрикс более 8 лет. Разработываю и поддерживаю сайты любой сложности, от Landing Page до крупных B2B проектов.</div>
                                    
                            <div className={style.banner__link}>
                                <ButtonCallback />
                            </div>
                                    
                            <div className={style.banner__social}>
                                <SocialList />
                            </div>
                        </div>
                    </div>
                    <div className={`${style.banner__col_img} col-40`}>
                        <div className={style.banner__img}>
                            <img src="/images/banner.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BannerHome;